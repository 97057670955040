import { createApi } from '@reduxjs/toolkit/dist/query/react';
import baseQuery from 'store/app/entities/baseQuery';

const initialState = {};

const teamEndpoints = build => ({
  listTeams: build.query({
    query: ({
      search,
      searchBy = search ? 'name' : undefined,
      page = 1,
      perPage = 99,
      sortBy = 'name',
      sort = 'asc',
      parent,
      withChildrenOf,
    } = {}) => {
      const params = new URLSearchParams(
        Object.entries({
          search,
          searchBy,
          page,
          perPage,
          sortBy,
          sort,
          parent,
          withChildrenOf,
        }).filter(([, value]) => value !== undefined && value !== null && value !== ''),
      );
      if (withChildrenOf === null) {
        params.set('withChildrenOf', 'null');
      }
      return {
        url: `v1/team?${params.toString()}`,
      };
    },
    transformResponse: response => response.data,
    providesTags: result =>
      result?.teams
        ? [
            ...result.teams.map(({ _id: id }) => ({ type: 'Teams', id })),
            { type: 'Teams', id: 'PARTIAL-LIST' },
          ]
        : [{ type: 'Teams', id: 'PARTIAL-LIST' }],
  }),
});

const api = createApi({
  reducerPath: 'teamsReducer',
  baseQuery,
  tagTypes: ['Teams'],
  initialState,
  keepUnusedDataFor: 30,
  endpoints: teamEndpoints,
});

export const {
  useListTeamsQuery,
  useLazyListTeamsQuery,
  util: { invalidateTags: invalidateTeamsTags },
} = api;

export const { middleware: teamsMiddleware, reducer: teamsReducer } = api;
