import Box from '@mui/material/Box';
import MessageFrom from 'components/@home/@messages/ChannelsPanel/MessageFrom/MessageFrom';
import MessagePreview from 'components/@home/@messages/ChatPanel/PinnedMessage/MessagePreview';
import { arrayOf, shape, string } from 'prop-types';
import React, { memo } from 'react';
import attachmentsShape from 'shapes/attachment';

const styles = {
  root: {},
  lastMessage: {
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    overflowWrap: 'break-word',
    lineHeight: '1.2em',
    '& *': {
      verticalAlign: 'text-bottom',
    },
    '& *:first-child': {
      marginRight: '2px',
    },
    '& *:last-child': {
      marginRight: 0,
    },
    '& > * + *': {
      marginLeft: '3px',
    },
  },
};

const LastMessage = ({ lastMessage }) => {
  if (!lastMessage || !lastMessage.from || !lastMessage.from._id) return null;
  return (
    <Box sx={styles.lastMessage}>
      <MessageFrom message={lastMessage}>
        {name => (name ? <strong>{name.trim()}:</strong> : null)}
      </MessageFrom>
      <MessagePreview message={lastMessage} />
    </Box>
  );
};

LastMessage.propTypes = {
  lastMessage: shape({
    attachments: arrayOf(attachmentsShape),
    text: string.isRequired,
    employee_id: string,
  }),
};
LastMessage.defaultProps = {
  lastMessage: null,
};

const isEqual = (prev, next) => {
  if (prev.lastMessage?.attachments !== next.lastMessage?.attachments) return false;
  if (prev.lastMessage?.text !== next.lastMessage?.text) return false;
  if (prev.lastMessage?.employee_id !== next.lastMessage?.employee_id) return false;
  return true;
};
export default memo(LastMessage, isEqual);
