export const REACTION_EMOJIS = {
  '+1': '👍',
  heart: '❤️',
  smile: '😄',
  open_mouth: '😮',
  tada: '🎉',
  clap: '👏',
  rocket: '🚀',
};

const REACTIONS = [...Object.keys(REACTION_EMOJIS), 'care'];

export default REACTIONS;
